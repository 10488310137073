.folders{
  margin-top:1%;
  margin-left:auto;
  margin-right:auto;
  width:50%;
  height:fit-content;
  padding:10px;
}

.folders h1{
  text-align: center;
  color:white;
  margin-bottom:5vh;
}

.addnote{
  margin-top:5px;
  padding:10px;
  margin-left:5px;
}

.backButton{
  color:white;
  border: 1px solid white;
  font-size: 20px;
}

.folders button{
  display:block;
  margin-top:15px;
  width: 80%;
  min-height: fit-content;
  margin-left:auto;
  margin-right:auto;
  font-size:18px;
}

.noteContent{
  color:white;
  margin-top:5vh;
  text-align: center;
  background-color: rgb(137, 18, 221);
  width:75%;
  height:25%;
  margin-left:auto;
  margin-right:auto;
}

.noteContent p{
  margin-top:10%;
}

.noteContent button{
  margin-top:20px;
  margin-right:10px;
  background-color: rgb(72, 11, 116);
  width:20%;
}

@media (min-width:1000px){

  .folders button{
    height:15%;
    margin-bottom:5%;
    font-size: 1.8rem;
  }

  .noteContent{
    font-size:25px;
  }

  .noteContent p{
    margin-top:3%;
    margin-bottom:3%;
  }

}