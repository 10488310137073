.calendarBox{
  margin-left:auto;
  margin-right:auto;

  height:50vh;
  width:50vw;
}

.newDeadline{
  width:75%;
  margin-top: 15%;
  margin-left:auto;
  margin-right:auto;
  color:white;
  background-color: rgb(137, 18, 221);
  text-align: center;
}

.newDeadline input{
  width:85%;
  margin-top:10px;
}

.newDeadline input[type='submit']{
  margin-top:5%;
  width:fit-content;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:10px;
  background-color: rgb(72, 11, 116);
}

.nice-dates-popover{
  width:75%;
  height:60%;
}

.deadlineButtons{
  margin-top:5px;
  margin-left:5px;
}


.newDeadline form{
  margin-top:10px;
}

.currentDeadlines{
  width:75%;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  color:white;
}

.deadline{
  text-align: center;
  background-color: rgb(137, 18, 221);
  margin-bottom:10px;
}

.deadline button{
  background-color: rgb(72, 11, 116);
  margin-bottom: 5px;
  height: 20%;
}

@media (min-width:1000px){

  .deadline{
    width:25%;
    margin-left:auto;
    margin-right:auto;
  }

  .newDeadline{
    font-size:30px;
  }

  .newDeadline form{
    width:50%;
    margin-left:auto;
    margin-right:auto;
  }

}
