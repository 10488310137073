*{
  box-sizing: border-box;
}


body{
  margin:0;
}



.fullPage{
height:60vh;
justify-content: space-evenly;
align-items: stretch;
display:inline-flex;
width:100%;
}

.fullPage > div{
  flex:1;
}

.leftBox{
  background-color: rgb(113, 36, 168);
  display:flex;
}

.rightBox{
  background-color: rgb(97, 9, 160);
}

.leftContent{
  color: white;
  padding:0;
  margin:auto;
  padding:10px;
}

.leftContent button{
  height: 40px;
  background-color:rgb(137, 18, 221);
  color:white;
  border:none;
  font-style: bold;
}


.meter { 
  height: 5px;
  background: rgb(97, 9, 160);
  overflow: hidden;
}

.meter span {
  display: block;
  height: 100%;
}

.progress {
  /* background-color: #e4c465; */
  background-color: orange;
  animation: progressBar 5s linear infinite;
  animation-fill-mode:both;
}

@keyframes progressBar {
0% { width: 0; }
100% { width: 100%; }
}

.dynamicRight{
  text-align: center;
  margin-top:2%;
  color:white;
}

.rightImage{
  max-width: 100%;
  max-height: 100%;
}











