main{
  background-color:rgb(72, 11, 116);
  height:100vh;
}

.loginPage{
  width:75vw;
  margin: 10vh auto;
  text-align: center;
  background-color:rgb(137, 18, 221);
  color:white;
}


.signForm{
  margin-bottom:5vh;
}

.signForm input{
  margin-bottom: 10px;
  width:auto;
}

.signForm input[type='submit']{
  margin-top:15px;
  width:auto;
  background-color: rgb(72, 11, 116);
  border:none;
  color:white;
}

.logInHead{
  margin-top:5vh;
  margin-bottom:5vh;
}