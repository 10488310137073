.dash-nav{
  display:inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color:rgb(97, 9, 160);
  height:50px;
}


 button, input[type='submit']{
  height:40px;
  border-radius: 5px;
  background:rgb(137, 18, 221);
  border-style:solid;
  border:none;
  color:white;
}