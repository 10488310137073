@import url(https://fonts.googleapis.com/css?family=Rubik&display=swap);
main{
  background-color:rgb(72, 11, 116);
  height:100vh;
}

.loginPage{
  width:75vw;
  margin: 10vh auto;
  text-align: center;
  background-color:rgb(137, 18, 221);
  color:white;
}


.signForm{
  margin-bottom:5vh;
}

.signForm input{
  margin-bottom: 10px;
  width:auto;
}

.signForm input[type='submit']{
  margin-top:15px;
  width:auto;
  background-color: rgb(72, 11, 116);
  border:none;
  color:white;
}

.logInHead{
  margin-top:5vh;
  margin-bottom:5vh;
}
.registrationPage{
  width:75vw;
  margin: 10vh auto;
  text-align: center;
  border: 1px solid black;
  background-color:rgb(137, 18, 221);
  color:white;
}

.signForm{
  margin-bottom:5vh;
}

.signForm input{
  margin-bottom: 10px;
  width:auto;
}

.signForm input[type='submit']{
  width:auto;
}

.logInHead{
  margin-top:5vh;
  margin-bottom:5vh;
}
.dash-nav{
  display:inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color:rgb(97, 9, 160);
  height:50px;
}


 button, input[type='submit']{
  height:40px;
  border-radius: 5px;
  background:rgb(137, 18, 221);
  border-style:solid;
  border:none;
  color:white;
}
.notecards{
  margin-top: 15px;
  width:65%;
  margin:0 auto; 
}

.notecard{
  text-align: center;
  margin-top:10px;
  height:25%;
  border:solid 1px black;
  background-color: white;
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/2/2e/Notecard.jpg');
  background-size: contain;
}

.button{
  display: inline-block;
  margin-right:5px;
  margin-bottom:5px;
}

input, textarea{
  width: 70%
}

.addNote, .noteHead{
  text-align: center;
  margin: 0 auto;
}

.popup-overlay{
  color:indigo;
}

.notecardTest{
  color:white;
  background-color:rgb(137, 18, 221);
  height:45%;
  margin-top:10vh;
  line-height: 60px;
  text-align: center;
  width:90%;
  margin-left:auto;
  margin-right:auto;
}

.notecardTest input{
  width:55vw;
  height:5vh;
  color:indigo;
  font-size: 25px;
}

.testForm{
  margin-top:5vh;
}

.testForm button{
  background-color:rgb(72, 11, 116);
}

.topButtons{
  margin-top:5px;
  width:100vw;
  margin-left:5px;
  text-align: left;
}

.testScore{
  margin-top:10vh;
  color:white;
  text-align: center;
}

.testScore button{
  margin-top: 10vh;
  margin-right:10px;
}

.feedback{
  color:white;
  margin-top:5vh;
  padding:10px;
  line-height: 30px;
  background-color: rgb(137, 18, 221);
}

.feedback p{
  -webkit-text-decoration: underline rgb(72, 11, 116);
          text-decoration: underline rgb(72, 11, 116);
}

.testbutton{
  width:60px;
}

@media (min-width:1000px){

  .notecards{
    width:30%;
  }

  .testForm{
    margin-top:5%;
  }

  .testForm label{
    font-size:25px;
  }

  .notecardTest input{
    height:3vh;
    width: 30%;
  }

}

.folders{
  margin-top:1%;
  margin-left:auto;
  margin-right:auto;
  width:50%;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  padding:10px;
}

.folders h1{
  text-align: center;
  color:white;
  margin-bottom:5vh;
}

.addnote{
  margin-top:5px;
  padding:10px;
  margin-left:5px;
}

.backButton{
  color:white;
  border: 1px solid white;
  font-size: 20px;
}

.folders button{
  display:block;
  margin-top:15px;
  width: 80%;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  margin-left:auto;
  margin-right:auto;
  font-size:18px;
}

.noteContent{
  color:white;
  margin-top:5vh;
  text-align: center;
  background-color: rgb(137, 18, 221);
  width:75%;
  height:25%;
  margin-left:auto;
  margin-right:auto;
}

.noteContent p{
  margin-top:10%;
}

.noteContent button{
  margin-top:20px;
  margin-right:10px;
  background-color: rgb(72, 11, 116);
  width:20%;
}

@media (min-width:1000px){

  .folders button{
    height:15%;
    margin-bottom:5%;
    font-size: 1.8rem;
  }

  .noteContent{
    font-size:25px;
  }

  .noteContent p{
    margin-top:3%;
    margin-bottom:3%;
  }

}
.calendarBox{
  margin-left:auto;
  margin-right:auto;

  height:50vh;
  width:50vw;
}

.newDeadline{
  width:75%;
  margin-top: 15%;
  margin-left:auto;
  margin-right:auto;
  color:white;
  background-color: rgb(137, 18, 221);
  text-align: center;
}

.newDeadline input{
  width:85%;
  margin-top:10px;
}

.newDeadline input[type='submit']{
  margin-top:5%;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:10px;
  background-color: rgb(72, 11, 116);
}

.nice-dates-popover{
  width:75%;
  height:60%;
}

.deadlineButtons{
  margin-top:5px;
  margin-left:5px;
}


.newDeadline form{
  margin-top:10px;
}

.currentDeadlines{
  width:75%;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  color:white;
}

.deadline{
  text-align: center;
  background-color: rgb(137, 18, 221);
  margin-bottom:10px;
}

.deadline button{
  background-color: rgb(72, 11, 116);
  margin-bottom: 5px;
  height: 20%;
}

@media (min-width:1000px){

  .deadline{
    width:25%;
    margin-left:auto;
    margin-right:auto;
  }

  .newDeadline{
    font-size:30px;
  }

  .newDeadline form{
    width:50%;
    margin-left:auto;
    margin-right:auto;
  }

}

*{
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
}

.Main_Header{
  padding:0;
}

.Header{
  display:inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 103vw;
  margin-left:-10px;
  background-color: rgb(137, 18, 221);
}

a{
  padding-left:25px;
  text-decoration: none;
  padding-right:10px;
  color:white;
}

.Header_Logout{
  padding-right:5vw;
}

.register{
  margin-right:5vw;
}


*{
  box-sizing: border-box;
}


body{
  margin:0;
}



.fullPage{
height:60vh;
justify-content: space-evenly;
align-items: stretch;
display:inline-flex;
width:100%;
}

.fullPage > div{
  flex:1 1;
}

.leftBox{
  background-color: rgb(113, 36, 168);
  display:flex;
}

.rightBox{
  background-color: rgb(97, 9, 160);
}

.leftContent{
  color: white;
  padding:0;
  margin:auto;
  padding:10px;
}

.leftContent button{
  height: 40px;
  background-color:rgb(137, 18, 221);
  color:white;
  border:none;
  font-style: bold;
}


.meter { 
  height: 5px;
  background: rgb(97, 9, 160);
  overflow: hidden;
}

.meter span {
  display: block;
  height: 100%;
}

.progress {
  /* background-color: #e4c465; */
  background-color: orange;
  -webkit-animation: progressBar 5s linear infinite;
          animation: progressBar 5s linear infinite;
  -webkit-animation-fill-mode:both;
          animation-fill-mode:both;
}

@-webkit-keyframes progressBar {
0% { width: 0; }
100% { width: 100%; }
}

@keyframes progressBar {
0% { width: 0; }
100% { width: 100%; }
}

.dynamicRight{
  text-align: center;
  margin-top:2%;
  color:white;
}

.rightImage{
  max-width: 100%;
  max-height: 100%;
}












button:hover, input[type='submit']:hover {
  background: orange;
  color:indigo;
  letter-spacing: 1px;
  box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
  transition: all 0.4s ease 0s;
  }

button{
  min-width:45px;
}
