.registrationPage{
  width:75vw;
  margin: 10vh auto;
  text-align: center;
  border: 1px solid black;
  background-color:rgb(137, 18, 221);
  color:white;
}

.signForm{
  margin-bottom:5vh;
}

.signForm input{
  margin-bottom: 10px;
  width:auto;
}

.signForm input[type='submit']{
  width:auto;
}

.logInHead{
  margin-top:5vh;
  margin-bottom:5vh;
}